module.exports = {
  "IDMClientOptions": {
    "externalAuth": false,
    "cookiesEndpoint": "https://auth-tcpdev.bccsa-services.ca",
    "apiEndpoint": "https://gopeqnn79k.execute-api.ca-central-1.amazonaws.com/dev",
    "roles": {
      "USER": "59a72bbd-9a43-427e-9c92-afd74ab19431",
      "ADMIN": "MY-APP-ADMIN-ROLE",
      "ROOT": "59a72bbd-9a43-427e-9c92-afd74ab19433"
    },
    "partitions": {
      "PERSONAL": "ca.bccsa.personal",
      "PROFESSIONAL": "ca.bccsa.professional"
    }
  },
  "IncludesStageOnName": true,
  "Stage": "dev",
  "ApplicationRecaptchaKey": "6LfO17sUAAAAADbEcJD9brTVgjw5uPW5h495bHwt",
  "ApplicationAdvancedSearchDisabled": true,
  "ApplicationRoutes": {
    "login": "/",
    "homepage": "/",
    "register": "/register",
    "registrationConfirmation": "/registrationConfirmation/:id/:id2",
    "registrationConfirmationMail": "/registrationConfirmation/:id",
    "registrationConfirmationReset": "/registrationConfirmation/reset/:id",
    "resetPassword": "/resetPass/:id",
    "resetPasswordRaw": "/resetPass/",
    "resetPasswordConfirmation": "/resetPass/confirm/:id",
    "setupPassword": "/setupPass/:id/:id2",
    "user": "/user/:id",
    "profile": "/user",
    "users": "/users",
    "applications": "/apps",
    "application": "/app/:id",
    "role": "/app/:id/role/:id2",
    "accessToken": "/app/:id/accessToken/:id2",
    "partitionACLs": "/acls",
    "partitionACL": "/acl/:id",
    "authError": "/authError",
    "smsMFA": "/smfa",
    "contactAdmin": "mailto:support@ikonintegration.com"
  },
  "ThemeDomainsMapping": {
    "*.bccsa-services.ca": {
      "themeKey": "bccsa",
      "adminRole": "4437c11c-250_BC___Students_Portal",
      "userRole": "89a14430-da4_BC___Students_Portal",
      "idmKey": "3a8f5fc9-9abe-4719-9bbf-4565d07efda8",
      "idmSecret": "1155027a-8471-4271-9b44-dfcdb7185edd",
      "idmExternalAuthDomain": "auth-tcpdev.bccsa-services.ca"
    }
  },
  "DatadogOptions": {
    "applicationId": "a8039f15-8b00-4cae-86af-e5f95cd24ece",
    "clientToken": "pub1e0e6e2f2186d5b5c5d0b66627a2481f",
    "site": "datadoghq.com",
    "service": "idm-fe",
    "env": "dev",
    "sampleRate": 100,
    "trackInteractions": true,
    "defaultPrivacyLevel": "allow"
  }
}